<template>
  <div class="bg-white px-3 py-4 md:p-8">
    <div class="mb-4 md:mb-6">
      <h4 class="text-subhead-3 md:text-h4 mb-1 md:mb-2">Available units</h4>
      <p class="text-caption-2 md:text-body text-black-70">
        Last update {{ format(new Date(item.updated_at), 'dd MMMM yyyy hh:mm') }}
      </p>
    </div>
    <div class="flex flex-col gap-10">
      <div class="hidden flex-col gap-10 md:flex">
        <div v-for="(type, index) in units" :key="index">
          <h5 class="mb-1">{{ type.title }}</h5>
          <div class="text-body mb-4 flex items-center gap-2 text-black-70">
            <p class="text-black-100">From {{ aedFormatter.format(type.price_min) }} {{ type.currency }}</p>
            •
            <p>{{ type.count }} units</p>
            •
            <p>{{ type.area_min }} - {{ type.area_max }} m<sup>2</sup></p>
          </div>
          <div class="flex flex-wrap gap-4">
            <PropertyTabsUnitCard
              v-for="(unit, i) in type.items"
              :key="i"
              :index="i"
              :item="unit"
              @click="showUnitPopup(unit, index)"
            />
          </div>
        </div>
      </div>
      <div class="block md:hidden">
        <client-only>
          <Swiper navigation :modules="[SwiperNavigation]" class="!overflow-y-visible">
            <SwiperSlide v-for="(plan, index) in units" :key="index" class="!w-full cursor-pointer select-none">
              <div>
                <h5 class="text-subhead-4 mb-1 text-center">{{ plan.title }}</h5>
                <div class="text-caption-2 mb-4 flex items-center justify-center gap-2 text-black-70">
                  <p>{{ plan.count }} units</p>
                  •
                  <p>{{ plan.area_min }} - {{ plan.area_max }} m<sup>2</sup></p>
                </div>
                <div class="flex w-full flex-wrap justify-center gap-4">
                  <PropertyTabsUnitCard
                    v-for="(unit, i) in plan.items"
                    :key="i"
                    :index="i"
                    :item="unit"
                    class="w-full"
                    @click="showUnitPopup(unit, index)"
                  />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </client-only>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { format } from 'date-fns'
import { POPUPS } from '@/components/dynamic/maps'
import { useUiStore } from '@/store/ui'
import type { PropertyTypes } from '@/types'

const uiStore = useUiStore()
const route = useRoute()

const aedFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
})

type Props = {
  item: PropertyTypes.PropertyDetailed
  isMobile?: boolean
}

const props = defineProps<Props>()

const units = computed(() => {
  if (!props.item.apartments.length) return []
  return getPropertyUnits(props.item.apartments)
})

const showUnitPopup = (item: PropertyTypes.Apartment, index: number) => {
  if (props.isMobile) {
    navigateTo(`/property/${route.params.hash}/unit/${item.id}?index=${index}`)
  } else {
    uiStore.showSidePanel(POPUPS.PROPERTY_UNIT_INFO, { item, index, complex: props.item })
  }
}
</script>

<style scoped lang="scss">
:deep() {
  .swiper {
    &-button-prev,
    &-button-next {
      @apply top-10 mt-0 size-4 -translate-y-full;

      &:after {
        @apply text-sm text-black-100;
      }
      &.swiper-button-disabled {
        opacity: 1;
        &:after {
          @apply text-black-40;
        }
      }
    }
    &-button-prev {
      @apply right-8;
      left: 0px;
    }
    &-button-next {
      @apply right-0;
    }
  }
}
</style>
